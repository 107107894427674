export const installClickInterceptor = (onClick: (href: string) => void) => {
	const clickInterceptor = (e: MouseEvent | TouchEvent) => {
		const target = e.target as HTMLElement;

		if (target.tagName === 'A') {
			e.preventDefault();
			onClick((target as HTMLAnchorElement).href);
		}
	};

	document.addEventListener('click', clickInterceptor);
	// document.addEventListener('touchstart', clickInterceptor);
	// document.addEventListener('pointerdown', clickInterceptor);
};

import { WidgetContextProvider } from '@smile/context';
import type { ImpressionSequence } from '@thanks/impression-type';
import { AnalyticsEffector } from '@theway/analytics';
import { useState } from 'react';
import { createRoot } from 'react-dom/client';

import { analytics } from '../analytics';
import { tunnel } from '../bridge';

import { useFocusRightControl } from '../components/focus-ring-control';
import { Shadow } from '../components/shadow';

import { getAppRenderer, ViewSelector } from './ViewSelector';

export type ViewOptions = {
	mode: 'desktop' | 'mobile';
	offsetTop: number | undefined;
	initialStep: number;
};

export type AppAPIOptions = {
	setViewOptions(newOptions: ViewOptions): void;
};

declare module '@smile/experimentation' {
	interface RuntimeConfiguration {
		/**
		 * controls "click outside" and "esc" key behavior
		 */
		disableClickOutside: boolean;
	}
}

export const renderApp = async (
	impression: ImpressionSequence,
	viewOptions: ViewOptions,
): Promise<AppAPIOptions> => {
	const onWidgetClose = async () => {
		analytics.phaseMetric('close');
		tunnel.send('close');
		await analytics.flush();
		tunnel.send('terminate');
	};

	let onSetViewOptions: (newOptions: ViewOptions) => void;

	function App() {
		const [usedViewOptions, setViewOptions] = useState(viewOptions);

		onSetViewOptions = setViewOptions;

		useFocusRightControl();

		return (
			<WidgetContextProvider
				impression={impression}
				mode={usedViewOptions.mode}
			>
				<ViewSelector
					impression={impression}
					onClose={onWidgetClose}
					{...usedViewOptions}
				/>
			</WidgetContextProvider>
		);
	}

	await getAppRenderer(impression.widget).preparePreset();

	createRoot(document.getElementById('app')!).render(
		<AnalyticsEffector onEvent={analytics.uiEvent}>
			<Shadow onClick={onWidgetClose}>
				<App />
			</Shadow>
		</AnalyticsEffector>,
	);

	return {
		// @ts-expect-error TS2454: Variable 'onSetViewOptions' is used before being assigned
		setViewOptions: onSetViewOptions,
	};
};

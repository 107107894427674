/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
export const installClarity = () => {
	(function (c, l, a, r, i, t, y) {
		c[a] =
			c[a] ||
			function () {
				(c[a].q = c[a].q || []).push(arguments);
			};

		t = l.createElement(r);
		t.async = 1;
		t.src = 'https://www.clarity.ms/tag/' + i;
		y = l.getElementsByTagName(r)[0];
		y.parentNode.insertBefore(t, y);
	})(window, document, 'clarity', 'script', 'hwv4j4hj53');
};

export const tagClaritySession = (impressionId: string) => {
	if (!window.clarity) {
		console.warn(
			'Clarity not initialised, will not attempt to link impression to Clarity session.',
		);

		return;
	}

	window.clarity('identify', impressionId, impressionId);
};

function isLocalStorageAccessible(localStorage: Storage) {
	try {
		// Try to use localStorage
		localStorage.setItem('uf-ls-test', 'test');
		localStorage.removeItem('uf-ls-test');

		return true;
	} catch (e) {
		// Catch any errors, including security-related ones
		return false;
	}
}

export const verifyWidgetCooldown = (
	publisherId: string,
	cooldownEnd: string,
	localStorage: Storage,
) => {
	if (!isLocalStorageAccessible(localStorage)) {
		console.log('cannot use local storage');

		return false;
	}

	// Smart Widget Window
	const cooldownKey = `sww_${publisherId}`;
	const cooldown = localStorage.getItem(cooldownKey);
	const isInCooldown = cooldown && new Date(cooldown) > new Date();

	if (!isInCooldown) {
		localStorage.setItem(cooldownKey, cooldownEnd);
	}

	return !isInCooldown;
};

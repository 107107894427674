import { analytics } from '../analytics';
import type { AppAPIOptions, ViewOptions } from '../app/App';

import { areAllStylesLoaded } from '../utils/styles-are-loaded';
import {
	getWidgetOffset,
	hasWidget,
	widgetCanFitScreen,
} from '../utils/widget-can-fit';

export const ensureWidget = (
	app: AppAPIOptions,
	viewOptions: ViewOptions,
	viewOptionsOverride: Partial<ViewOptions>,
) => {
	if (!areAllStylesLoaded()) {
		analytics.phaseMetric('fail', { reason: 'missing styles' });
		throw new Error('not all styles loaded');
	}

	if (!hasWidget()) {
		analytics.phaseMetric('fail', { reason: 'no widget' });
		throw new Error('there is no widget');
	}

	if (!widgetCanFitScreen()) {
		const offset = getWidgetOffset();

		if (
			offset.top > 0 &&
			offset.bottom < 0 &&
			-offset.bottom < offset.top
		) {
			viewOptionsOverride.offsetTop = offset.top + offset.bottom;

			console.error(
				'widget offset at the edge, topOffset corrected to fit the screen',
				offset,
			);

			app.setViewOptions({
				...viewOptions,
				...viewOptionsOverride,
			});
		} else {
			analytics.phaseMetric('fail', {
				reason: 'small screen',
				height: offset.budget,
				offset: `${offset.top}:${offset.bottom}`,
			});

			// throw new Error('widget is too big for screen');
			return 'widget is too big for screen';
		}
	}

	return;
};

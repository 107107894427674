import { css } from '@linaria/core';
import { useRuntimeConfigurationRef } from '@smile/experimentation';
import { useAnalyticsEvent } from '@theway/analytics';
import type { FC, MouseEvent, PropsWithChildren } from 'react';
import { useCallback } from 'react';

const shadow = css`
	position: absolute;
	inset: 0;

	width: 100%;
	height: 100%;

	background-color: rgba(0, 0, 0, 0.5);

	overflow: hidden;

	display: grid;
	justify-items: center;
`;

export const Shadow: FC<PropsWithChildren<{ onClick(): void }>> = ({
	children,
	onClick,
}) => {
	const uiEvent = useAnalyticsEvent();
	const configurationRef = useRuntimeConfigurationRef();
	const onShadowClick = useCallback(
		(e: MouseEvent) => {
			if (e.currentTarget === e.target) {
				if (configurationRef.current.disableClickOutside) {
					uiEvent('click-outside-suppressed', e.currentTarget);
				} else {
					uiEvent('click-outside', e.currentTarget);

					onClick();
				}
			}
		}, // TODO: fix eslint error
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	);

	return (
		<div className={shadow} onClick={onShadowClick}>
			{children}
		</div>
	);
};

function isStylesheetLoaded(stylesheetHref: string, sheets: CSSStyleSheet[]) {
	return sheets.some((sheet) => sheet.href === stylesheetHref);
}

export const areAllStylesLoaded = () => {
	// Automatically populate stylesheetsToCheck with links from document header
	const stylesheetsToCheck = Array.from(
		document.head.getElementsByTagName('link'),
	)
		.filter((link) => link.rel === 'stylesheet' && link.href)
		.map((link) => link.href);

	const sheets = Array.from(document.styleSheets).filter((sheet) => {
		try {
			return sheet.cssRules.length > 0;
		} catch (e) {
			return false;
		}
	});

	return stylesheetsToCheck.every((href) => isStylesheetLoaded(href, sheets));
};

import { useEffect } from 'react';

export const useFocusRightControl = () => {
	useEffect(() => {
		document.body.style.setProperty('--focus-ring-disabled', '');

		const onTab = (e: KeyboardEvent) => {
			if (e.key === 'Tab') {
				document.body.style.removeProperty('--focus-ring-disabled');
			}
		};

		document.body.addEventListener('keydown', onTab);

		return () => {
			document.body.removeEventListener('keydown', onTab);
		};
	}, []);
};

const getWidget = () => document.querySelector('.widget-top-wrapper');

export const hasWidget = () => {
	return Boolean(getWidget());
};

export const getWidgetOffset = () => {
	const widget = getWidget();
	const rect = widget!.getBoundingClientRect();

	return {
		top: rect.y,
		bottom: window.innerHeight - rect.bottom,
		budget: rect.height,
	};
};

export const widgetCanFitScreen = () => {
	const { top, bottom } = getWidgetOffset();

	return top >= 0 && bottom >= 0;
};
